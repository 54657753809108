<template>
  <!-- 客户账龄 -->
  <div class="customer_box">
    <div class="customer_search_box">
      <div class="search-item">
        <span>关键字</span>
        <el-input style="width: 176px;" clearable placeholder="请输入客户名称" size="small" v-model="filterinfo.search"></el-input>
      </div>
      <div class="btn_box">
        <el-button class="btn_item" type="primary" size="small" @click="getCustomerList(1)">查询</el-button>
        <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
      </div>
    </div>
    <div class="table_mode">
      <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" stripe style="width: 100%">
        <el-table-column prop="companyName" label="客户名称" min-width="200" :show-overflow-tooltip="true" align="center" fixed="left"></el-table-column>
        <el-table-column prop="businessDepart" label="部门" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="memberLevel" label="等级" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period0" label="总账期" sortable="custom" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period1" label="30天内" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period2" label="31-60" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period3" label="61-90" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period4" label="91-120" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period5" label="121-150" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period6" label="151-180" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period7" label="181-210" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period8" label="211-240" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period9" label="241-270" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period10" label="271-300" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period11" label="301-330" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period12" label="331-360" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <el-table-column prop="Period13" label="一年以上" sortable="custom" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
        <div slot="empty" class="nodata_box">
          <img src="@/assets/images/index/nodata.png"/>
          <span class="txt">暂无数据哦~</span>
        </div>
      </el-table>
    </div>
    <div class="pagination_box">
      <el-pagination
        background
        layout="prev, pager, next, sizes, total"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="filterinfo.pageSize"
        :current-page.sync="filterinfo.page"
        :total="total"
        @current-change="getCustomerList"
        @size-change="getCustomerList(1)">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
  name: 'CustomerAccounts',
  data() {
    return {
      loading: false,
      total: 0,
      filterinfo:{
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      },
      tableData:[],
      attributeOptions: [],
      options: [],
    };
  },
  created() {

  },
  mounted(){
    this.getCustomerList()
  },
  methods: {
    // 点击重置
    resetClick(){
      this.filterinfo = {
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      }
      this.getCustomerList()
    },
    // 获取客户账龄列表
    getCustomerList(num){
      if(num==1){
        this.filterinfo.page = 1
      }
      let params = this.filterinfo
      this.loading = true
      this.$api.pcBillList(params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.tableData = handlerTableData(res.data.data.list)
          // this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      });
    },
    handleSortChange({ column, prop, order }) {
      if(order == 'ascending'){
        this.filterinfo.orderBy = prop + " asc"
      }else{
        this.filterinfo.orderBy = prop + " desc"
      }
      this.getCustomerList()
    },
  }
};
</script>
<style lang="scss" scoped>
.customer_box{
  position: relative;
}
</style>